import React from 'react';

export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.survey.hmformation.fr'
    : 'http://localhost:5042';

export const ERRORS = {
  400: (
    <div>
      A problem appeared on the server. I advise you to re-try and if it still
      doesn't work,{' '}
      <a
        href="https://twitter.com/VictorBaudot_"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        send me a message on Twitter
      </a>
      .
    </div>
  )
};

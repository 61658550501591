import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default () => (
  <Fragment>
    <Helmet>
      <title>Page introuvable - HM Formation / Survey</title>
    </Helmet>
    <div className="">
      <h2>404 - Page introuvable</h2>
      <Link to="/" className="">
        Retourner sur la page d'accueil
      </Link>
    </div>
  </Fragment>
);

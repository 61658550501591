import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { API_URL } from '../../utils/constants';
import PrivateRoute from './PrivateRoute';
import { Login, Home, NotFound } from '../../pages';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('x-auth');
    if (token && token !== 'undefined') {
      return axios
        .post(`${API_URL}/auth/jwt`, { token })
        .then(() => this.setState({ isLoading: false }))
        .catch(() => {
          localStorage.removeItem('x-auth');
          this.setState({ isLoading: false });
        });
    }
    return this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) return null;
    return (
      <Router>
        <Fragment>
          <Helmet>
            <title>Survey - HM Formation</title>
            <meta
              property="og:url"
              content="https://www.survey.hmformation.fr/"
            />
          </Helmet>
          <Switch>
            <Route exact path="/login" render={props => <Login {...props} />} />
            <PrivateRoute
              exact
              path="/"
              component={Home}
              handleLogout={this.handleLogout}
            />
            <Route component={NotFound} />
            />
          </Switch>
        </Fragment>
      </Router>
    );
  }
}

export default App;

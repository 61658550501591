import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import logo from '../logo.png';
import { API_URL } from '../utils/constants';

const handleKeyPress = (e, onSubmit) => {
  if (e.key === 'Enter') {
    onSubmit();
  }
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      isSuccess: false,
      isLoading: false,
      error: null
    };
  }

  render() {
    const { username, password, isLoading, isSuccess, error } = this.state;
    return (
      <main>
        <div className="outer h-screen">
          <Helmet>
            <title>Connexion - Survey - HM Formation</title>
          </Helmet>
          <div className="inner max-w-xs">
            <img src={logo} className="block mx-auto mb-6" alt="logo" />
            <div className="form">
              <div className="field-group mb-6 md:w-full">
                <label className="field-label" htmlFor="identifiant">
                  Identifiant
                </label>
                <input
                  className="field"
                  type="text"
                  name="identifiant"
                  placeholder="Elie"
                  onChange={ev => this.handleChangeField('username', ev)}
                  value={username}
                  onKeyPress={ev => handleKeyPress(ev, this.handleSubmit)}
                  required
                  autoFocus
                />
              </div>
              <div className="field-group mb-6 md:w-full">
                <label className="field-label" htmlFor="password">
                  Mot de passe
                </label>
                <input
                  className="field"
                  type="password"
                  name="password"
                  placeholder="*********"
                  onChange={ev => this.handleChangeField('password', ev)}
                  value={password}
                  onKeyPress={ev => handleKeyPress(ev, this.handleSubmit)}
                  required
                />
              </div>
              <div className="flex flex-col">
                <button
                  className="btn btn-primary mx-auto mb-2"
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={isLoading || isSuccess}
                >
                  Se connecter
                </button>
                {isSuccess && (
                  <p className="text-center text-green text-sm">
                    Connexion réussie
                  </p>
                )}
                {error && (
                  <p className="text-center text-red text-sm">{error}</p>
                )}
              </div>
            </div>
            <p className="text-center text-primary-light text-xs">
              ©2019 Survey - HM Formation.
            </p>
          </div>
        </div>
      </main>
    );
  }

  handleSubmit = async () => {
    const { username, password } = this.state;
    if (!username || !password) return;
    this.setState({ isLoading: true });
    return axios
      .post(`${API_URL}/login`, {
        username,
        password
      })
      .then(res => {
        this.setState({ isLoading: false });
        if (res.data && res.data.token) {
          this.setState({ isSuccess: true });
          localStorage.setItem('x-auth', res.data.token);
          this.props.history.push('/');
        }
      })
      .catch(err => {
        let msg = 'Une erreur est apparue.';
        console.error(err.response);
        if (err && err.response && err.response.status === 404) {
          msg = "Cet utilisateur n'existe pas.";
        } else if (err && err.response && err.response.status === 400) {
          msg = 'Mot de passe invalide.';
        }
        this.setState({ error: msg, isLoading: false });
      });
  };

  handleChangeField = (key, event) => {
    this.setState({
      [key]: event.target.value
    });
  };
}

export default Login;

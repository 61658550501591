import React, { Fragment } from 'react';

import { Label } from '../../components/commons';

export default ({
  fieldName,
  label,
  type,
  placeholder,
  value,
  onChangeField,
  defaultHint,
  course,
  courseYear,
  surveyee
}) => (
  <Fragment>
    <Label htmlFor={fieldName}>
      {label}
      {fieldName.includes('surveyId') && value.length > 0 && (
        <span>
          {' '}
          -{' '}
          <a
            href={`https://docs.google.com/forms/d/e/${value}/viewform?usp=pp_url${
              surveyee.surveyNameId && surveyee.name
                ? `&entry.${surveyee.surveyNameId}=${surveyee.name}`
                : ''
            }${
              surveyee.surveyCourseId && course
                ? `&entry.${surveyee.surveyCourseId}=${course}`
                : ''
            }${
              surveyee.surveyYearId && courseYear
                ? `&entry.${surveyee.surveyYearId}=${courseYear}`
                : ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            voir
          </a>
        </span>
      )}
    </Label>
    <input
      id={fieldName}
      className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white border-grey-lighter focus:border-grey"
      type={type}
      onChange={ev => onChangeField(fieldName, ev)}
      value={value || ''}
      placeholder={placeholder}
      required
    />
    {defaultHint && (
      <p className="text-grey-dark text-xs italic">{defaultHint}</p>
    )}
  </Fragment>
);

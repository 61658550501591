import React, { Fragment } from 'react';

import Input from './Input';

export default ({
  surveyee,
  surveyeeType,
  onChangeField,
  toggleExtra,
  course,
  courseYear
}) => (
  <Fragment>
    <div className="flex mt-10 mb-4 justify-between items-baseline">
      <div className="block uppercase tracking-wide text-grey-darker text-lg font-bold">
        <span className="underline-primary">
          Informations {surveyeeType === 'intern' ? 'Stagiaire' : 'N+1'}
        </span>
      </div>
      <div
        className="block uppercase tracking-wide text-blue-light text-xs font-bold hover:text-blue cursor-pointer"
        onClick={() => toggleExtra(surveyeeType)}
      >
        {surveyee.displayExtra ? 'Cacher' : 'Changer de questionnaire'}
      </div>
    </div>
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full sm:w-1/2 px-3 mb-6 sm:mb-0">
        <Input
          fieldName={`${surveyeeType}.name`}
          label={'Prénom Nom'}
          type="text"
          placeholder="Sammy BRUE"
          value={surveyee.name}
          onChangeField={onChangeField}
          defaultHint={`Prénome et nom du ${
            surveyeeType === 'intern' ? 'stagiaire' : 'N+1'
          }`}
        />
      </div>
      <div className="w-full sm:w-1/2 px-3">
        <Input
          fieldName={`${surveyeeType}.email`}
          label={'Email'}
          type="email"
          placeholder="sammy@example.com"
          value={surveyee.email}
          onChangeField={onChangeField}
          defaultHint={`Email du ${
            surveyeeType === 'intern' ? 'stagiaire' : 'N+1'
          }`}
        />
      </div>
    </div>
    {surveyee.displayExtra && (
      <Fragment>
        <div className="flex -mx-3 mb-6">
          <div className="w-full px-3">
            <Input
              fieldName={`${surveyeeType}.surveyId`}
              label={'Questionnaire ID'}
              type="text"
              value={surveyee.surveyId}
              onChangeField={onChangeField}
              defaultHint={`Questionnaire ID du ${
                surveyeeType === 'intern' ? 'stagiaire' : 'N+1'
              }`}
              course={course}
              courseYear={courseYear}
              surveyee={surveyee}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full sm:w-1/3 px-3 mb-6 sm:mb-0">
            <Input
              fieldName={`${surveyeeType}.surveyNameId`}
              label={'Nom ID'}
              type="text"
              value={surveyee.surveyNameId}
              onChangeField={onChangeField}
              defaultHint={'ID du champs "Nom"'}
            />
          </div>
          <div className="w-full sm:w-1/3 px-3">
            <Input
              fieldName={`${surveyeeType}.surveyCourseId`}
              label={'Formation ID'}
              type="text"
              value={surveyee.surveyCourseId}
              onChangeField={onChangeField}
              defaultHint={'ID du champs "Formation"'}
            />
          </div>
          <div className="w-full sm:w-1/3 px-3">
            <Input
              fieldName={`${surveyeeType}.surveyYearId`}
              label={'Année de formation ID'}
              type="text"
              value={surveyee.surveyYearId}
              onChangeField={onChangeField}
              defaultHint={'ID du champs "Année de formation"'}
            />
          </div>
        </div>
      </Fragment>
    )}
  </Fragment>
);

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { omit } from 'lodash';

import logo from '../../logo.png';
import { API_URL } from '../../utils/constants';
import Input from './Input';
import TypeForm from './TypeForm';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      course: '',
      courseYear: null,
      intern: {
        name: '',
        email: '',
        surveyId: '1FAIpQLSeoIOp9BP5R1i8wGtapyCj0oALvJESFM_Q8BGeOsnxRwsPc7g',
        surveyNameId: '186686382',
        surveyCourseId: '473419385',
        surveyYearId: '903157058',
        displayExtra: false
      },
      tutor: {
        name: '',
        email: '',
        surveyId: '1FAIpQLSet-HC8xB6T0CtEidbK7ni4sqAwT4AdezzAvmoBdxTZwxgv_w',
        surveyNameId: '186686382',
        surveyCourseId: '473419385',
        surveyYearId: '903157058',
        displayExtra: false
      },
      isLoading: false,
      isSuccess: false,
      isClearingLogsLoading: false,
      isClearingLogsSuccess: false,
      isModalActive: false,
      saveError: null
    };
  }

  render() {
    const {
      course,
      courseYear,
      intern,
      tutor,
      isClearingLogsLoading,
      isClearingLogsSuccess,
      isLoading,
      isModalActive,
      isSuccess,
      saveError
    } = this.state;
    return (
      <main>
        <div className="outer">
          <Helmet>
            <title>Survey - HM Formation</title>
          </Helmet>
          <div
            className={`modal fixed z-50 pin overflow-auto flex items-center p-8 justify-center${
              isModalActive ? ' visible' : ' invisible'
            }`}
          >
            <div
              className="fixed pin overflow-auto bg-primary opacity-50 cursor-pointer"
              onClick={this.toggleModal}
            />
            <div className="animated fadeInUp max-w-sm relative pin-b pin-x align-top m-auto justify-end justify-center p-8 bg-white rounded w-full h-auto shadow-lg flex flex-col">
              <div className="text-center mb-6 text-2xl font-semibold text-grey-darker">
                Êtes-vous sûr de vouloir vider le fichier logs.csv ?
              </div>
              <div className="flex items-baseline -mx-3 justify-center">
                <div className="px-3">
                  <button
                    className="inline-block align-baseline font-bold text-sm text-grey-darker hover:text-black capitalize"
                    onClick={this.toggleModal}
                  >
                    Retour
                  </button>
                </div>
                <div className="px-3">
                  <button
                    className={`${isClearingLogsLoading ? 'spinner ' : ''}bg-${
                      isClearingLogsSuccess ? 'red-light' : 'red'
                    } hover:bg-${
                      isClearingLogsSuccess ? 'red-light' : 'red'
                    }-dark text-white font-bold py-4 px-16 tracking-wide mb-3 rounded focus:outline-none focus:shadow-outline uppercase`}
                    type="button"
                    onClick={this.clearLogs}
                    disabled={isClearingLogsLoading || isClearingLogsSuccess}
                  >
                    {isClearingLogsSuccess ? 'Fichier vidé.' : 'Oui, vider'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="inner max-w-lg">
            <img src={logo} className="block mx-auto my-12" alt="logo" />
            <div className="form">
              <div className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full sm:w-1/2 px-3 mb-6 sm:mb-0">
                    <Input
                      fieldName="course"
                      label={'Formation'}
                      type="text"
                      placeholder="Maitriser Excel"
                      value={course}
                      onChangeField={this.handleChangeField}
                      defaultHint={'Intitulé de la formation'}
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-3">
                    <Input
                      fieldName="courseYear"
                      label={'Année de formation'}
                      type="number"
                      placeholder="2019"
                      value={courseYear}
                      onChangeField={this.handleChangeField}
                      defaultHint={'Année de la formation'}
                    />
                  </div>
                </div>
                <TypeForm
                  surveyee={intern}
                  surveyeeType="intern"
                  onChangeField={this.handleChangeField}
                  toggleExtra={this.toggleExtra}
                  course={course}
                  courseYear={courseYear}
                />
                <TypeForm
                  surveyee={tutor}
                  surveyeeType="tutor"
                  onChangeField={this.handleChangeField}
                  toggleExtra={this.toggleExtra}
                  course={course}
                  courseYear={courseYear}
                />
                <div className="flex items-baseline justify-between mt-10">
                  <div className="flex items-baseline">
                    <div className="px-3">
                      <a
                        className="border shadow py-3 px-6 inline-block align-baseline font-bold text-sm text-grey-dark hover:no-underline hover:text-blue-darkest"
                        href={`${API_URL}/surveyees/logs`}
                        download
                      >
                        Liste des mails envoyés
                      </a>
                    </div>
                    <div className="px-3">
                      <button
                        className="inline-block align-baseline font-bold text-sm text-red-light hover:text-red focus:outline-none"
                        onClick={this.toggleModal}
                      >
                        Vider
                      </button>
                    </div>
                  </div>
                  <div className="px-3">
                    <button
                      className={`${isLoading ? 'spinner ' : ''}bg-${
                        isSuccess ? 'green' : 'primary'
                      }-light hover:bg-${
                        isSuccess ? 'green' : 'primary'
                      } text-white uppercase font-bold py-4 px-10 mb-3 rounded focus:outline-none focus:shadow-outline mr-2 tracking-wide`}
                      type="submit"
                      onClick={this.handleSubmit}
                      disabled={isLoading || isSuccess}
                    >
                      {isSuccess ? 'Envoyés' : 'Envoyer'}
                    </button>
                  </div>
                </div>
                {saveError && (
                  <p className="text-right text-red text-xs italic">
                    {saveError}
                  </p>
                )}
                {isSuccess && (
                  <p className="text-right text-green text-xs italic">
                    Les emails vont bientôt être délivrés
                  </p>
                )}
              </div>
            </div>
            <p className="text-center text-primary-lighter text-xs">
              ©2019 Survey - HM Formation.
            </p>
          </div>
        </div>
      </main>
    );
  }

  toggleModal = () =>
    this.setState(({ isModalActive }) => ({ isModalActive: !isModalActive }));

  clearLogs = () => {
    console.log('logs cleared');
    const token = localStorage.getItem('x-auth');
    this.setState({ isClearingLogsLoading: true });
    return axios
      .delete(`${API_URL}/surveyees/logs`, {
        headers: {
          Authorization: token
        }
      })
      .then(res => {
        this.setState(prevState => ({
          isClearingLogsLoading: false,
          isClearingLogsSuccess: true
        }));
      })
      .catch(err => {
        console.error(err.response);
        this.setState({ isClearingLogsLoading: false });
      });
  };

  toggleExtra = type => {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        displayExtra: !prevState[type].displayExtra
      }
    }));
  };

  handleSubmit = async () => {
    const { course, courseYear, intern, tutor } = this.state;
    if (
      !course ||
      ((!intern.name || !intern.email) &&
        (!intern.name || !tutor.name || !tutor.email))
    )
      return;
    const token = localStorage.getItem('x-auth');
    this.setState({ isLoading: true });
    return axios
      .post(
        `${API_URL}/surveyees`,
        {
          course,
          courseYear,
          intern: intern.name ? omit(intern, 'displayExtra') : null,
          tutor: tutor.email && tutor.name ? omit(tutor, 'displayExtra') : null
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
      .then(res => {
        this.setState(prevState => ({
          intern: { ...prevState.intern, name: '', email: '' },
          tutor: { ...prevState.tutor, name: '', email: '' },
          isLoading: false,
          isSuccess: true,
          saveError: null
        }));
      })
      .catch(err => {
        let msg =
          'Erreur. Veuillez vérifier les informations du formulaire puis réessayer.';
        console.error(err.response);
        this.setState({ saveError: msg, isLoading: false });
      });
  };

  handleChangeField = (key, event) => {
    const value = event.target.value;
    if (key === 'course' || key === 'courseYear') {
      this.setState({
        saveError: null,
        isSuccess: false,
        isClearingLogsSuccess: false,
        [key]: value
      });
    } else {
      this.setState(prevState => ({
        saveError: null,
        isSuccess: false,
        isClearingLogsSuccess: false,
        [key.split('.')[0]]: {
          ...prevState[key.split('.')[0]],
          [key.split('.')[1]]: value
        }
      }));
    }
  };

  handleLogout = () => {
    localStorage.removeItem('x-auth');
    this.props.history.push('/login');
  };
}

export default Home;

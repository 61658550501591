import axios from 'axios';

import { API_URL } from './constants';

export default () => {
  const token = localStorage.getItem('x-auth');
  if (token && token !== 'undefined') {
    return axios.post(`${API_URL}/auth/jwt`, { token });
  }
  return false;
};
